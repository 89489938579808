$(document).ready(function() {

	/* Menu
	 *****************************************/
	var menu = ".js-menu",
		open = ".js-menu-trigger"

	$(open).click(function() {
		$(menu).toggleClass("open");
		$('body').toggleClass("unscroll");
	});

	$(document).keyup(function(e) {
		if (e.keyCode == 27) {
			$(menu).removeClass("open");
			$('body').removeClass("unscroll");
		}
	});

});
