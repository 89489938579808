$(document).ready(function() {

	/* Scroll
	*****************************************/
	var scrollClass = ".js-scroll"

	$(scrollClass).click(function() {
	  event.preventDefault();
	  var target = $(this).attr("data-target");

	  $("html, body").stop().animate({
	    scrollTop:$(target).offset().top
	  }, 500);

	});

});
