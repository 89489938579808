$(document).ready(function() {

	/* Modal
	 *****************************************/
	var modal = ".js-modal",
		open = ".js-modal-open",
		close = ".js-modal-close"

	if (Cookies.get('elModalOpen') == 'true') {
		$(modal).addClass("open");
		$('body').addClass("unscroll");
	}

	$(open).click(function() {
		$(modal).addClass("open");
		$('body').addClass("unscroll");
		Cookies.set('elModalOpen', true)
		console.log(Cookies.get('elModalOpen'))
	});

	$(close).click(function() {
		$(modal).removeClass("open");
		$('body').removeClass("unscroll");
		Cookies.set('elModalOpen', false)
		console.log(Cookies.get('elModalOpen'))

	});

	$(document).keyup(function(e) {
		if (e.keyCode == 27) {
			$(modal).removeClass("open");
			$('body').removeClass("unscroll");
			Cookies.set('elModalOpen', false)
			console.log(Cookies.get('elModalOpen'))

		}
	});

});
