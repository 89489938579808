$(document).ready(function() {

	function setCookie(key, value) {
		var expires = new Date();
		expires.setTime(expires.getTime() + 1 * 1000 * 3600 * 24 * 30); //1 month
		document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString();
	}

	function getCookie(key) {
		var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
		return keyValue ? keyValue[2] : null;
	}

	function displayCookieBar(){
		if ( getCookie('isCookieBarActive') != 'false' ){
			setCookie('isCookieBarActive', 'true')

			$('.cookiebar').addClass('active')
		} else {
			$('.cookiebar').removeClass('active')
		}

		$('.js-accept-cookies').on('click', function(){
			setCookie('isCookieBarActive', 'false')
			$('.cookiebar').removeClass('active')
		})
	}

	/*
	Display Cookiebar onload
	*/
	$(function(){
		displayCookieBar()
	})

});
